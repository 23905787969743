import { Drawer, Line, Select, Skeleton, Text } from "components/commons";
import ProductCard from "components/commons/card/product-card";
import React, { useRef, useMemo, useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ProductDetail } from "modules";
import { sortByKeyName } from "services";
import lang from "translations";
import useDrawer from "hooks/useDrawer";
import classnames from "classnames";
import CartButton from "./cart-button";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import LazyLoad from "react-lazyload";

const ProductCardList = ({
  products = [],
  className,
  loading = true,
  total = 0,
  searchKey = "",
  fetchList,
  requestState,
  isSearchOpen = false,
  cart,
  setCart,
  tableName,
}) => {
  const categoryRefs = useRef({});
  const dropdownRef = useRef({});
  const categoryDropdown = useRef();
  const [selectedCategory, setSelectedCategory] = useState();
  const [offsetTop, setOffsetTop] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const productDrawer = useDrawer();
  const scrollIntoView = (type) => {
   categoryRefs.current[type].scrollIntoView();
  };

  const groupProductsByCategory = useCallback(() => {
    const categoryMap = new Map();
    let productsArr =
      isSearchOpen && (searchKey === "" || searchKey === null) ? [] : products;
    productsArr.forEach((p) => {
      p.category.forEach((c) => categoryMap.set(c.categoryId, c.categoryName));
    });

    categoryMap.set(0, "Others");
    return Array.from(categoryMap).map(([key, value]) => ({
      id: key,
      name: value,
    }));
  }, [products, isSearchOpen, searchKey]);

  const checkIfProductBelongsToCategory = (productId, categoryId, products) => {
    const product = products.find((p) => p.id === productId) || {};
    if (categoryId === 0) {
      return !product.category || product.category.length === 0;
    } else {
      return product.category?.some((c) => c.categoryId === categoryId);
    }
  };

  const productCategories = useMemo(() => {
    const productCategories = groupProductsByCategory();
    const map = new Map();
    productCategories.forEach((pc) => {
      const productArray = [];
      let productsArr =
        isSearchOpen && (searchKey === "" || searchKey === null)
          ? []
          : products;
      productsArr.forEach((product) => {
        const { id, isAllCategories } = product;
        const isCategorized = checkIfProductBelongsToCategory(
          id,
          pc.id,
          products
        );
        if (isCategorized && !isAllCategories) {
          productArray.push(product);
        } else if (!isCategorized && isAllCategories) {
          productArray.push(product);
        } else if (
          isCategorized &&
          isAllCategories &&
          productCategories.length === 1
        ) {
          productArray.push(product);
        }
      });
      map.set(pc.name, productArray);
    });
    let sortedMap = new Map([...map.entries()].sort());
    const others = sortedMap.get("Others");
    sortedMap.delete("Others");
    sortedMap.set("Others", others);
    return sortedMap;
  }, [groupProductsByCategory, products, searchKey, isSearchOpen]);

  const categoryOptions = useMemo(() => {
    const productCategories = groupProductsByCategory(products);
    let sortedCategories = productCategories
      .sort(sortByKeyName("name"))
      .map((category) => {
        return {
          text: (
            <Text
              fontWeight="font-semibold"
              color="text-black-light"
              className="tracking-wide"
            >
              {category.name}
            </Text>
          ),
          title: category.name,
          value: category.name,
        };
      });
    let sortedCategoryOptions = sortedCategories
      .filter((x) => x.title !== "Others")
      .concat(sortedCategories.filter((x) => x.title === "Others"));
    return sortedCategoryOptions;
  }, [groupProductsByCategory, products]);

  const showCategoryDropdown = (event) => {
    setOffsetTop(event.target.scrollTop);
    categoryDropdown.current?.blur();
  };

  window.onresize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  const getProductQty = (productId) => {
    const productInCart = cart?.find((item) => item.id === productId);
    return productInCart?.quantity;
  };

  const getProductNotes = (productId) => {
    const productInCart = cart?.find((item) => item.id === productId);
    return productInCart?.notes;
  };

  if (loading) {
    return (
      <div>
        <Skeleton className="bg-white mt-sm p-md" />
      </div>
    );
  }

  const getCartInfo = () => {
    let totalQty = 0;
    let totalPrice = 0;
    cart?.forEach((c) => {
      totalQty = totalQty + c.quantity;
      totalPrice = totalPrice + c.totalPrice;
    });

    return `${lang.cart} ${totalQty > 0 ? `(${totalQty})` : ""} ${
      totalPrice > 0
        ? `- ${formatNumberToMoneyWithCurrencySymbol(totalPrice)}`
        : ""
    }`;
  };
  return !loading && total > 0 ? (
    <div
      className={classnames("overflow-y-scroll h-screen w-full", isMobile ? "pb-60" : "pb-40")}
      onScroll={showCategoryDropdown}
    >
      <div
        className={classnames("bg-washed py-sm px-md z-20 sticky top-0", {
          hidden:
            offsetTop === 0 ||
            (isSearchOpen && (searchKey === "" || !searchKey)),
          visible: offsetTop !== 0,
        })}
        ref={(el) =>
          (dropdownRef.current = {
            ...dropdownRef.current,
            dropdown: el,
          })
        }
      >
        <Select
          ref={categoryDropdown}
          sortOptions={false}
          options={categoryOptions}
          value={selectedCategory}
          onChange={(v, option) => {
            setSelectedCategory(`${option.value}`);
            scrollIntoView(option.value);
          }}
          placeholder="Select a category.."
        />
      </div>
      {[...productCategories].map((value) => {
        const [key, categories] = value;
        return (
          categories?.length > 0 && (
            <div
              className={classnames("bg-white pt-md pb-sm mt-sm", className)}
              key={uuidv4()}
              ref={(el) =>
                (categoryRefs.current = {
                  ...categoryRefs.current,
                  [`${key}`]: el,
                })
              }
            >
              {!loading ? (
                <>
                  <div className="px-md">
                    <Text size="text-lg" fontWeight="font-semibold" breakAll>
                      {key}
                    </Text>
                  </div>
                  {categories.map((cp, index) => {
                    const { id } = cp;
                    cp.notes = getProductNotes(id);
                    return (
                      <div key={id}>
                        <LazyLoad height={164} offset={160} once>
                          <ProductCard
                            {...cp}
                            quantity={getProductQty(id)}
                            loading={loading}
                            onClick={() => {
                              productDrawer.show({
                                content: (
                                  <ProductDetail
                                    cart={cart}
                                    setCart={setCart}
                                    product={cp}
                                    onClose={() => {
                                      productDrawer.close();
                                    }}
                                    fetchProducts={() => {
                                      fetchList(requestState);
                                    }}
                                  />
                                ),
                              });
                            }}
                          ></ProductCard>
                        </LazyLoad>
                        {index !== categories.length - 1 ? <Line /> : null}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="p-lg">
                  <Skeleton />
                </div>
              )}
            </div>
          )
        );
      })}
      <CartButton
        cart={cart}
        setCart={setCart}
        fetchProducts={fetchList}
        tableName={tableName}
        loadingProduct={loading}
        requestState={requestState}
        cartInfo={getCartInfo()}
      />
      <Drawer
        placement="right"
        paddingless
        width={isMobile ? "100%" : "50%"}
        {...productDrawer}
      />
    </div>
  ) : (
    <div className="pt-lg pb-sm my-xl flex flex-col items-center gap-4">
      {searchKey ? (
        <div className="px-xl text-center">
          <Text breakAll size="text-lg" fontWeight="font-bold">
            {lang.populate(lang.sorryWeCantFind, [searchKey])}
          </Text>
          <Text className="break-word mt-sm">{lang.tryDifferentKeyword}</Text>
        </div>
      ) : (
        <div className="p-lg">
          <Skeleton />
        </div>
      )}
    </div>
  );  
};

export default ProductCardList;
