import React from "react";

const LayoutC = ({ leftChild, rightChild }) => {
	return (
		<div className="h-full w-full md:mt-0 md:flex md:flex-row">
			<div className="w-full mt-0 pb-xxl md:w-1/2 fixed">{leftChild}</div>
			<div
				className="bg-white-darker text-center w-0 md:w-1/2 md:flex 
						   items-center hidden md:justify-center 
				           md:fixed md:inset-y-0 
				           md:right-0"
			>
				{rightChild}
			</div>
		</div>
	);
};

export default LayoutC;
