import { Text, Title, Icon, Button, Drawer } from "components/commons";
import lang from "translations";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import ProductDetail from "../view-product/product-detail.module";
import { ProductType } from "enums";
import useDrawer from "hooks/useDrawer";
import { sortByKeyName } from "services";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import styles from "./cart-item-list.module.scss";
import { useModal } from "hooks";
import { useMemo } from "react";
import CartRemoveItemModal from "./cart-remove-modal";

const CartItemList = ({
	cartItems = [],
	setCart,
	fetchTotalPrice,
	invalidItems,
	setInvalidItems,
	closeCart,
	showCart,
	fetchCart,
}) => {
	const editProductDrawer = useDrawer();
	const removeItemModal = useModal();
	const cart = useMemo(() => {
		let cartArr = [];
		let isUnavailable
		cartItems?.forEach((item) => {
			const {
				id,
				name,
				variants = [],
				type,
				quantity,
				retailPrice,
				initialVariants,
				imageLink,
				description,
				notes
			} = item;
			if (type === ProductType.Variant) {
				variants.forEach((variant) => {
					const {
						name: variantName,
						productSkuId,
						quantity,
						retailPrice,
						notes
					} = variant;
					if (invalidItems) {
						isUnavailable = invalidItems.some(
							(error) =>
								error === productSkuId
						)
					}
					cartArr.push({
						name,
						variantName,
						id,
						productSkuId,
						totalPrice: quantity * retailPrice,
						retailPrice,
						quantity,
						type,
						initialVariants,
						imageLink,
						description,
						variant,
						variants,
						isUnavailable,
						notes
					});
				});
			} else {
				if (invalidItems) {
					isUnavailable = invalidItems.some(
						(error) =>
							error === id
					)
				}
				cartArr.push({
					id,
					name,
					type,
					retailPrice,
					totalPrice: quantity * retailPrice,
					quantity,
					imageLink,
					description,
					isUnavailable,
					notes
				});
			}
		});

		fetchTotalPrice(cartArr);
		return cartArr;
	}, [cartItems, invalidItems, fetchTotalPrice]);

	if (!cart.some((item) => item.isUnavailable === true)) {
		setInvalidItems(null)
	}

	return (
		<div className="bg-white p-5">
			<div className="flex items-center justify-between">
				<Title lg className="justify-self-start">
					{lang.cartItems}
				</Title>
				<Text className="font-bold" underline>
					{lang.addItems}
				</Text>
			</div>
			{cart && cart.length !== 0 ? (
				<div>
					{cart.sort(sortByKeyName("name")).map((item, index) => {
						const {
							name,
							quantity = 0,
							retailPrice,
							totalPrice = 0,
							variantName,
							type,
							isUnavailable,
							notes
						} = item;
						return (
							<div
								key={uuidv4()}
								className={`flex justify-between py-4 ${index + 1 !== cart.length ? "border-b" : ""
									}`}
							>
								<div
									className={classNames(
										"rounded-lg border-gray-300 border border-solid flex justify-center items-center font-bold mr-3",
										styles.quantityContainer
									)}
								>
									<Text fontWeight="font-semibold">{`${quantity}x`}</Text>
								</div>
								<div
									className={classNames(
										"flex-col justify-center",
										{ "w-1/3": totalPrice >= 1000 },
										{ "w-1/2": totalPrice < 1000 }
									)}
								>
									<Title>{name}</Title>
									{type === ProductType.Variant && (
										<Text label className="text-sm">
											{variantName}
										</Text>
									)}
									<Text label className="text-sm">
										{formatNumberToMoneyWithCurrencySymbol(
											retailPrice
										)}
										<Text color="text-gray" className="text-sm">
											{notes}
										</Text>
									</Text>
									{!isUnavailable ? <Button
										customPadding="px-0"
										type="plain"
										className="border-none"
										onClick={() => {
											closeCart();
											editProductDrawer.show({
												product: item,
												isOnEdit: true,
												cart: cartItems,
												setCart: setCart,
												showCart: () => showCart(),
											});
										}}
									>
										<Text className="underline font-bold">
											{lang.edit}
										</Text>
									</Button> :
										<Text
											className="text-red-400"
											fontWeight="font-bold">
											{lang.itemUnavailable}
										</Text>
									}
								</div>
								<Text className="text-sm p-1 font-bold text-right flex-auto">
									{formatNumberToMoneyWithCurrencySymbol(
										totalPrice
									)}
								</Text>
								<Icon
									name="remove1"
									fontSize="20px"
									className="font-bold text-gray-400 ml-2"
									onClick={() => {
										removeItemModal.show({
											product: item,
											cart: cartItems,
											setCart: setCart,
											showCart: () => showCart(),
										});
									}}
								/>
							</div>
						);
					})}
				</div>
			) : (
				<div className="text-center flex-auto flex-col justify-between">
					<Text label className="text-base m-16 mb-16">
						{lang.yourCartIsEmpty}
					</Text>
					<Button
						className="w-full border-black hover:border-black hover:text-black focus:border-black"
						type="secondary"
						onClick={() => {
							fetchCart();
							closeCart();
						}}
					>
						<Text className="text-lg font-bold">
							{lang.backToMenu}
						</Text>
					</Button>
				</div>
			)}
			{cart && cart.length !== 0 && (
				<Text italic label>
					{lang.availableDiscountsAndVouchers}
				</Text>
			)}
			<Drawer
				placement={window.innerWidth > 768 ? "right" : "bottom"}
				paddingless
				close={() => editProductDrawer.close()}
				height="100%"
				width="50%"
				destroyOnClose
				content={
					<ProductDetail
						onClose={() => editProductDrawer.close()}
						isOnEdit
						{...editProductDrawer}
					/>
				}
				{...editProductDrawer}
			/>
			<CartRemoveItemModal {...removeItemModal} />
		</div>
	);
};
export default CartItemList;
