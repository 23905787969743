import React, { useCallback, useMemo, useState, useRef } from "react";
import {
  Title,
  Text,
  Button,
  RadioGroup,
  Icon,
  Modal,
  TextArea,
} from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import lang from "translations";
import { CartOperation, ProductType } from "enums";
import classNames from "classnames";
import styles from "./product-detail.module.scss";
import { useForm, useModal } from "hooks";
import initialFormState from "./product-detail-state";
import { add, multiply } from "services/number.service";

const ProductDetail = ({
  product = {},
  onClose,
  isOnEdit = false,
  cart = [],
  setCart,
  showCart,
}) => {
  const {
    id,
    name = "",
    imageLink = "",
    price = "",
    description = "",
    variants = [],
    retailPrice = 0,
    quantity = 0,
    rawPrice = 0,
    initialVariants = [],
    variant,
    type,
    notes,
  } = product;

  const removeItemModal = useModal();
  const titleRef = useRef({});
  const [titleOverflown, setTitleOverflown] = useState(false);
  const [productQuantity, setProductQuantity] = useState(
    !isOnEdit ? 1 : quantity
  );
  const [productValue, setProductValue] = useState(
    !isOnEdit ? (variants ? variants[0]?.retailPrice : rawPrice) : retailPrice
  );
  const [selectedVariant, setSelectedVariant] = useState(
    !isOnEdit ? (variants ? variants[0] : null) : variant
  );

  const getVariantNotes = (id, productSkuId) => {
    let existingItem = cart?.find((item) => item.id === id) || {};
    let productVariant = existingItem.variants?.find(
      (v) => v.productSkuId === productSkuId
    );
    if (productVariant === undefined || productVariant === null) {
      return "";
    }
    return productVariant?.notes;
  };

  const formState = useMemo(() => {
    return initialFormState({
      id: id,
      notes:
        type === ProductType.Variant
          ? getVariantNotes(id, selectedVariant.productSkuId)
          : notes,
    });
    // eslint-disable-next-line
  }, [id, notes, type]);

  const { fields, modifyField } = useForm({
    initialState: formState,
  });

  const variantArr = useMemo(() => {
    return !isOnEdit && type === ProductType.Variant
      ? variants
      : initialVariants;
  }, [initialVariants, type, variants, isOnEdit]);

  const addItemToCart = useCallback(
    async (product) => {
      let cartCopy = cart;
      let existingItem = cartCopy.find((item) => item.id === product.id);
      if (existingItem) {
        if (existingItem.type === ProductType.Variant) {
          let existingVariant = await existingItem.variants.find(
            (variant) => variant.productSkuId === selectedVariant?.productSkuId
          );
          if (existingVariant) {
            existingVariant.quantity =
              existingVariant.quantity +
              (isOnEdit ? quantity : productQuantity);
            existingVariant.totalPrice =
              existingVariant.retailPrice * existingVariant.quantity;
            existingVariant.notes = fields.notes.value
              ? fields.notes.value
              : existingVariant.notes;
          } else {
            let existingVariants = existingItem.variants;
            let productVariant = await product.variants.find(
              (variant) => variant.productSkuId === selectedVariant.productSkuId
            );
            productVariant.quantity = productVariant.quantity
              ? productVariant.quantity + productQuantity
              : productQuantity;

            productVariant.totalPrice = quantity * productVariant.retailPrice;
            productVariant.notes = fields.notes.value
              ? fields.notes.value
              : productVariant.notes;
            existingItem.variants = [...existingVariants, productVariant];
          }
          let totalQuantity = 0;
          let totalPrice = 0;
          await existingItem.variants?.forEach((v) => {
            totalQuantity = totalQuantity + v.quantity;
            totalPrice = totalPrice + v.retailPrice * v.quantity;
          });
          existingItem.quantity = totalQuantity;
          existingItem.totalPrice = totalPrice;
        } else {
          existingItem.quantity = existingItem.quantity + productQuantity;
          existingItem.totalPrice =
            existingItem.quantity * existingItem.retailPrice;
          existingItem.notes = fields.notes.value
            ? fields.notes.value
            : existingItem.notes;
        }
        setProductQuantity(1);
      } else {
        product.quantity = productQuantity;
        const {
          id,
          quantity,
          name,
          variants = [],
          price,
          rawPrice,
          type,
          imageLink,
          description,
        } = product;
        if (type === ProductType.Variant) {
          let selectedProduct =
            variants.find(
              (variant) => variant.productSkuId === selectedVariant.productSkuId
            ) || {};
          selectedProduct.quantity = productQuantity;
          selectedProduct.notes = fields.notes.value;
          cartCopy.push({
            id,
            name,
            variants: [selectedProduct],
            type,
            imageLink,
            description,
            initialVariants: variants,
            price,
            quantity,
            totalPrice: selectedProduct.retailPrice * productQuantity,
            retailPrice: selectedProduct.retailPrice,
          });
        } else {
          cartCopy.push({
            id,
            quantity,
            name,
            price,
            type,
            imageLink,
            description,
            totalPrice: rawPrice * productQuantity,
            retailPrice: rawPrice,
            notes: fields.notes.value,
          });
        }
      }
      setCart(cartCopy);
      onClose();
    },
    [
      cart,
      setCart,
      onClose,
      selectedVariant?.productSkuId,
      quantity,
      productQuantity,
      isOnEdit,
      fields.notes.value,
    ]
  );

  const editCartItem = useCallback(
    (item) => {
      let cartCopy = cart;
      let existingItem = cartCopy.find((cart) => cart.id === item.id);
      if (existingItem) {
        if (type === ProductType.Variant) {
          let onEditVariant = variant;
          let productSkuId = selectedVariant.productSkuId;
          let existingVariant = existingItem.variants.find(
            (v) => v.productSkuId === selectedVariant.productSkuId
          );
          if (onEditVariant.productSkuId === productSkuId) {
            existingVariant.quantity = productQuantity;
            existingVariant.totalPrice = productQuantity * productValue;
          } else if (
            onEditVariant.productSkuId !== productSkuId &&
            existingVariant
          ) {
            existingVariant.quantity = productQuantity;
            existingVariant.totalPrice = productQuantity * productValue;
            let filteredVariants = existingItem.variants?.filter(
              (item) => item.productSkuId !== onEditVariant.productSkuId
            );
            existingItem.variants = filteredVariants;
          } else if (
            onEditVariant.productSkuId !== productSkuId &&
            !existingVariant
          ) {
            selectedVariant.quantity = productQuantity;
            selectedVariant.totalPrice = productQuantity * productValue;
            let filteredVariants = existingItem.variants?.filter(
              (item) => item.productSkuId !== onEditVariant.productSkuId
            );
            existingItem.variants = filteredVariants;
            existingItem.variants.push(selectedVariant);
          }

          selectedVariant.notes = fields.notes.value;
          let variants = existingItem.variants;
          let totalQty = 0;
          let totalPrice = 0;
          variants.forEach((variant) => {
            totalQty = add(totalQty, variant.quantity);
            totalPrice = add(totalPrice, variant.totalPrice);
          });
          existingItem.quantity = totalQty;
          existingItem.totalPrice = totalPrice;
        } else {
          existingItem.quantity = productQuantity;
          existingItem.totalPrice = productQuantity * productValue;
          existingItem.notes = fields.notes.value;
        }
      }
      setCart(cartCopy);
      onClose();
      showCart();
    },
    // eslint-disable-next-line
    [
      cart,
      setCart,
      onClose,
      productQuantity,
      productValue,
      type,
      selectedVariant,
      showCart,
      fields.notes.value,
    ]
  );

  const updateItem = useCallback(
    (operation) => {
      let cartCopy = cart;
      if (operation === CartOperation.Add) {
        setProductQuantity(productQuantity + 1);
      }
      if (operation === CartOperation.Remove) {
        setProductQuantity(productQuantity - 1);
      }
      setCart(cartCopy);
    },
    [setCart, cart, productQuantity]
  );

  const removeItem = useCallback(
    (productId) => {
      let cartCopy = cart;
      let existingItem = cartCopy.find((cart) => cart.id === id);
      if (type === ProductType.Variant) {
        let variantToRemove = variants.find(
          (v) => v.productSkuId === variant.productSkuId
        );
        if (variantToRemove) {
          variants.splice(variantToRemove, 1);
          existingItem.quantity = variants.reduce((total, item) => {
            return total + item?.quantity;
          }, 0);
        }
        if (variants.length < 1) {
          cartCopy = cartCopy.filter((item) => item.id !== productId);
        }
      } else {
        cartCopy = cartCopy.filter((item) => item.id !== productId);
      }
      setCart(cartCopy);
      onClose();
      showCart();
    },
    // eslint-disable-next-line
    [cart, onClose, setCart, showCart, id, type, variants]
  );
  return (
    <>
      <div
        className={classNames(styles.cartItems)}
        ref={titleRef}
        onScroll={() => {
          setTitleOverflown(titleRef.current.scrollTop > 200);
        }}
      >
        <div
          style={{
            backgroundImage: `url(${
              imageLink ?? "/static/media/default-image.451b1096.svg"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "200px",
          }}
        ></div>
        <Icon
          name="remove"
          className="fixed top-0 mt-md ml-sm text-gray-light z-20"
          fontSize="20px"
          onClick={() => {
            onClose();
          }}
        />
        <div className={classNames("flex flex-col justify-between")}>
          <div>
            <div
              className={classNames(
                "px-md pt-md z-10",
                {
                  "sticky bg-white text-center shadow-md pb-md px-xl":
                    titleOverflown,
                },
                styles.title
              )}
            >
              <Title>{name}</Title>
            </div>
            <div className="p-md pt-0">
              <div>
                <Text className="text-sm mt-sm mb-md" color="text-gray">
                  {description}
                </Text>
                <Title lg className="mb-md ">
                  {price}
                </Title>
              </div>
              {type === ProductType.Variant && (
                <div className="border-t border-gray-200">
                  <Title className="mt-md mb-md text-base">
                    {lang.selectOne}
                  </Title>
                  <RadioGroup
                    options={variantArr
                      .sort((a, b) => a.retailPrice - b.retailPrice)
                      .map((variant) => {
                        return {
                          key: variant.productSkuId,
                          customText: (
                            <div className="flex items-center justify-between w-full mb-sm">
                              <div className="w-2/3">
                                <Text
                                  breakAll
                                  fontWeight={
                                    selectedVariant?.productSkuId ===
                                    variant.productSkuId
                                      ? "font-semibold"
                                      : "font-normal"
                                  }
                                >
                                  {variant.name}
                                </Text>
                              </div>
                              <div>
                                <Text
                                  breakAll
                                  fontWeight={
                                    selectedVariant?.productSkuId ===
                                    variant.productSkuId
                                      ? "font-semibold"
                                      : "font-normal"
                                  }
                                >
                                  {formatNumberToMoneyWithCurrencySymbol(
                                    variant.retailPrice
                                  )}
                                </Text>
                              </div>
                            </div>
                          ),
                          value: variant.productSkuId,
                        };
                      })}
                    value={selectedVariant?.productSkuId}
                    onChange={async (value) => {
                      let variant = variantArr.find(
                        (variant) => variant.productSkuId === value
                      );
                      setProductValue(variant?.retailPrice);
                      setSelectedVariant(variant);
                      let notes = getVariantNotes(id, variant.productSkuId);
                      modifyField("notes", {
                        value: notes,
                      });
                    }}
                  />
                </div>
              )}
              <div className="border-t border-gray-200">
                <TextArea
                  {...fields.notes}
                  rows={1}
                  resizeable={false}
                  paddingless
                  className="mt-md pl-md"
                  placeholder={lang.leaveANote}
                  onChange={modifyField}
                ></TextArea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames("px-md pb-md", styles.cartButton)}>
        <div className="flex items-center justify-between py-md">
          <Icon
            name="minus"
            fontSize="18px"
            onClick={() => {
              if (
                (!isOnEdit && productQuantity <= 1) ||
                (isOnEdit && productQuantity === 0)
              ) {
                return;
              }
              updateItem(CartOperation.Remove);
            }}
            color={
              (!isOnEdit && productQuantity <= 1) ||
              (isOnEdit && productQuantity === 0)
                ? "text-gray-light"
                : "text-black"
            }
          />
          <Title>{productQuantity}</Title>
          <Icon
            name="plus"
            fontSize="24px"
            onClick={() => {
              updateItem(CartOperation.Add);
            }}
          />
        </div>
        {!isOnEdit ? (
          <Button
            className="w-full"
            onClick={() => {
              addItemToCart(product);
            }}
          >{`${lang.addToCart} - ${formatNumberToMoneyWithCurrencySymbol(
            multiply(productQuantity, productValue)
          )}`}</Button>
        ) : productQuantity > 0 ? (
          <Button
            className="w-full"
            onClick={() => {
              editCartItem(product);
            }}
          >
            {`${lang.updateCart} - ${formatNumberToMoneyWithCurrencySymbol(
              multiply(productQuantity, productValue)
            )}`}
          </Button>
        ) : (
          <Button
            className="w-full"
            onClick={() => {
              removeItemModal.show();
            }}
          >
            {lang.remove}
          </Button>
        )}
      </div>
      <Modal
        {...removeItemModal}
        noHeader
        closable
        width={280}
        actionContent={
          <div className="pr-2 pb-3 flex justify-end">
            <Button
              type="plain"
              className="hover:text-black border-none"
              onClick={() => removeItemModal.close()}
            >
              <Text size="text-base" color="text-gray" fontWeight="font-bold">
                {lang.cancel}
              </Text>
            </Button>
            <Button
              type="plain"
              className="hover:text-black border-none"
              onClick={() => removeItem(id)}
            >
              <Text
                color="text-red-light"
                size="text-base"
                fontWeight="font-bold"
              >
                {lang.remove}
              </Text>
            </Button>
          </div>
        }
      >
        <Title className="pt-5 pl-2.5" >
          {lang.removeItem}
        </Title>
      </Modal>
    </>
  );
};

export default ProductDetail;
