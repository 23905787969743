export const environment = {
	id: "dev",
	production: false,
	API: "https://dev.api.pouchnation.com",
	API_V3: "https://dev.gw.pouchnation.com",
	URL: "https://dev.ordering.pouchnation.com",
	AUTH: {
		login: "https://dev.accounts.pouchnation.com/auth",
		register: "https://dev.accounts.pouchnation.com/register",
		cognitoClientId: "271m6qco4quqfpgq4npd448h7f",
		clientId: "ORDERING",
	},
	FIREBASE: {
		apiKey: "AIzaSyBwTQaxZOqLXOCDe0Aytj8vw9qPB1UkJVo",
		authDomain: "ms-communication-ae098.firebaseapp.com",
		databaseURL: "https://ms-communication-ae098.firebaseio.com",
		projectId: "ms-communication-ae098",
		storageBucket: "ms-communication-ae098.appspot.com",
		messagingSenderId: "139918626078",
		appId: "1:139918626078:web:c8900ad0dffdb794538d0f",
		measurementId: "G-BG2Z7LL2BK",
	},
	MIXPANEL_TOKEN: "dd2b9cdaccee496cd63dd45ac4f09f57",
	FEATURE_FLAG: {
		clientSideID: "5f7ad1ef2229060b440fd8bb",
	},
	ACTIVE_MQ_HOST:
		"wss://b-72cc9200-234b-44da-b219-54d0578327ff-1.mq.ap-southeast-1.amazonaws.com:61619",
	ACTIVE_MQ_USER: "pndev",
	ACTIVE_MQ_PW: "pouchnationdev123",
	POUCH_ACCOUNTS: "https://dev.accounts.pouchnation.com",
	CONNECTOR_URL: "https://dev.connector.pouchnation.com",
	VENUE_PAGE: "https://dev.venue.pouchnation.com/redirect",
	DOCUMENTATION_URL:
		"https://pouchconnect.readme.io/reference/getting-started-connect",
};
