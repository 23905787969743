import { placeProductOrder, validateProductOrder } from "apis/product.api";
import {
	Drawer,
	Form,
	Icon,
	Input,
	Button,
	Text,
	Title,
	Toast,
	TextArea,
} from "components/commons";
import useDrawer from "hooks/useDrawer";
import { DateTime, ProductType } from "enums";
import { useApi, useForm } from "hooks";
import React, { useCallback, useState, useMemo, useRef } from "react";
import {
	formatDate,
	getCart,
	getLocationId,
	getTableId,
	getVenueId,
} from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import lang from "translations";
import OrderConfirmation from "../order-confirmation/order-confirmation.module";
import CartItemList from "./cart-item-list";
import initialFormState from "./review-cart-state";
import classnames from "classnames";
import style from "./cart-item-list.module.scss";
import { clearCart } from "services/app.service";
const ReviewCart = ({
	location,
	tableName = null,
	fetchList,
	requestState,
	// cart = [],
	setCart,
	...props
}) => {
	const cart = getCart();
	const orderConfirmationDrawer = useDrawer();
	const [totalPrice, setTotalPrice] = useState();
	const [invalidItems, setInvalidItems] = useState();
	const [isValidated, setIsValidated] = useState(false);

	const toast = useRef(
		Toast({
			content: (
				<div className="flex justify-between transition">
					<Text color="text-white" className="w-4/5 text-left">
						{lang.itemsInCartUpdated}
					</Text>
					<Icon
						name="clear"
						color="text-white"
						onClick={() => toast.current.close()}
					/>
				</div>
			),
			className: "bg-black",
			maxCount: 1,
			duration: 5,
		})
	);

	const formState = useMemo(() => {
		return initialFormState({ tableName: tableName, notes: null });
	}, [tableName]);

	const { fields, modifyField, submitForm, getFormValues } = useForm({
		initialState: formState,
	});

	const {
		request: placeOrderRequest,
		loading: placingOrder,
		mappedData,
		error: placingOrderError,
	} = useApi({
		api: placeProductOrder,
		mapper: {
			note: { key: "notes" },
			orderNumber: { key: "orderNumber" },
			dateCreated: {
				transform: ({ src }) => {
					return formatDate(src.createdDate, DateTime.I);
				},
			},
		},
		handleOwnError: true,
	});

	const { request: validateProductRequest, loading: validating } = useApi({
		api: validateProductOrder,
		mapper: {
			note: { key: "notes" },
			orderNumber: { key: "orderNumber" },
			dateCreated: {
				transform: ({ src }) => {
					return formatDate(src.createdDate, DateTime.I);
				},
			},
		},
		handleOwnError: true,
	});

	const cartQuantity = useMemo(() => {
		let quantity = cart?.reduce((total, item) => {
			return total + item?.quantity;
		}, 0);
		return quantity;
	}, [cart]);

	const calculateTotalPrice = useCallback((cart) => {
		let totalPrice = 0;
		if (cart) {
			totalPrice = cart.reduce((total, item) => {
				return total + item?.totalPrice;
			}, 0);
		}
		setTotalPrice(totalPrice);
	}, []);

	const submitOrder = useCallback(async () => {
		try {
			const params = getFormValues();
			const { tableName: tableNumber, notes } = params;
			let orders = [];
			cart.forEach((item) => {
				const {
					id,
					variants,
					type,
					totalPrice,
					quantity,
					retailPrice,
					notes,
					name,
				} = item;
				if (type === ProductType.Variant) {
					variants?.forEach((variant) => {
						const { productSkuId, quantity, retailPrice, notes } =
							variant;
						orders.push({
							productSkuId,
							quantity,
							retailPrice,
							notes,
							itemName: name,
							totalAmount: quantity * retailPrice,
						});
					});
				} else {
					orders.push({
						itemName: name,
						productSkuId: id,
						retailPrice,
						quantity,
						totalAmount: totalPrice,
						notes,
					});
				}
			});
			let orderForm = {
				venueId: getVenueId(),
				locationId: getLocationId(),
				tableId: getTableId(),
				onlineOrderItems: orders,
				totalAmount: totalPrice,
				defaultName: tableName ? tableName : `Table #${tableNumber}`,
				notes: notes,
			};
			let validationResponse;
			if (!isValidated) {
				validationResponse = await validateProductRequest(orderForm);
				if (
					!validationResponse?.metadata?.updatedIds[0] ||
					(!validationResponse?.metadata?.invalidIds[0] &&
						!validating)
				) {
					placeOrderRequest(orderForm);
					props.close();
					orderConfirmationDrawer.show({
						tableNumber: tableName ? tableName : tableNumber,
						cartItems: cart,
						totalPrice,
						cartQuantity: cartQuantity,
						placingOrderError,
						fetchList: () => {
							fetchList(requestState);
						},
						setCart: setCart,
					});
				}
				clearCart();
			}
			if (isValidated) {
				await placeOrderRequest(orderForm);
				props.close();
				orderConfirmationDrawer.show({
					tableNumber: tableName ? tableName : tableNumber,
					cartItems: cart,
					totalPrice,
					cartQuantity: cartQuantity,
					placingOrderError,
					fetchList: () => {
						fetchList(requestState);
					},
					setCart: setCart,
				});
			}
		} catch (error) {
			const { metadata } = error;
			toast.current.open();
			if (metadata?.updatedIds[0] && !metadata?.invalidIds[0]) {
				setIsValidated(true);
			}
			if (metadata?.invalidIds[0]) {
				setIsValidated(true);
				setInvalidItems(metadata?.invalidIds);
			}
		}
	}, [
		getFormValues,
		cart,
		totalPrice,
		tableName,
		validateProductRequest,
		isValidated,
		placeOrderRequest,
		props,
		orderConfirmationDrawer,
		cartQuantity,
		placingOrderError,
		setCart,
		fetchList,
		requestState,
		validating,
	]);

	const reviewCartDrawerHeader = useMemo(() => {
		return (
			<div className="bg-white w-full shadow-2xl items-center sticky top-0 flex z-10">
				<Icon
					name="left-arrow"
					fontSize="50px"
					className="flex-none"
					color="text-gray-light"
					onClick={() => {
						fetchList(requestState);
						props.close();
					}}
				/>
				<div className="text-center flex-auto mr-15 py-sm">
					<Text label fontWeight="font-bold">
						{tableName
							? `${location} - ${tableName}`
							: fields.tableName.value
							? `${location} - Table #${fields.tableName.value}`
							: location}
					</Text>
					<Title lg>
						{lang.cart} ({cartQuantity || 0})
					</Title>
				</div>
			</div>
		);
	}, [
		cartQuantity,
		fetchList,
		requestState,
		location,
		props,
		tableName,
		fields.tableName.value,
	]);

	const orderForForm = useMemo(() => {
		return (
			<div className="bg-white p-5 my-2">
				<Form>
					<Title lg className="mb-2">
						{lang.orderFor}
					</Title>
					<Text label className="text-sm mb-1 font-bold">
						{lang.whereAreYouSeated}
					</Text>
					<Input
						{...fields.tableName}
						disabled={tableName}
						placeholder={lang.enterTableNumber}
						iconPrefix={
							<Text className="mr-2 text-sm">{lang.table}# </Text>
						}
						onChange={modifyField}
						className="focus:border-black"
					/>
				</Form>
			</div>
		);
	}, [tableName, fields.tableName, modifyField]);

	const reviewCartNote = useMemo(() => {
		if (cart && cart.length !== 0) {
			return (
				<div className="bg-white-darker pt-2">
					<Form>
						<div className="bg-white p-5">
							<Text
								label
								className="text-sm flex items-center font-bold"
							>
								{lang.note}
								<Text label className="ml-1">
									({lang.optional})
								</Text>{" "}
							</Text>
							<TextArea
								{...fields.notes}
								rows={1}
								resizeable={false}
								paddingless
								className="pl-md"
								placeholder={lang.leaveANote}
								onChange={modifyField}
							></TextArea>
						</div>
					</Form>
				</div>
			);
		}
		return null;
	}, [cart, modifyField, fields.notes]);

	const reviewCartTotal = useMemo(() => {
		if (cart && cart.length !== 0) {
			return (
				<div
					className={classnames(
						"p-md w-full",
						style.reviewCartButton
					)}
				>
					<div className="flex items-center justify-between">
						<Title lg>{lang.total}</Title>
						<Text className="text-lg font-bold">
							{formatNumberToMoneyWithCurrencySymbol(totalPrice)}
						</Text>
					</div>
					<Button
						loading={validating || placingOrder}
						disabled={
							invalidItems || placingOrder || validating
								? true
								: false
						}
						className="w-full mt-2"
						onClick={() => {
							submitForm(submitOrder);
						}}
					>
						<Text
							className={`font-bold ${
								invalidItems || validating
									? "text-white-darkest"
									: "text-white"
							}`}
							fontSize="text-md"
						>
							{lang.placeOrder}
						</Text>
					</Button>
				</div>
			);
		}
		return null;
	}, [
		cart,
		totalPrice,
		invalidItems,
		submitForm,
		submitOrder,
		placingOrder,
		validating,
	]);

	return (
		<>
			<Drawer
				placement="right"
				width={window.innerWidth < 768 ? "100%" : "50%"}
				paddingless
				header={reviewCartDrawerHeader}
				customClose={() => {
					props.close();
					fetchList(requestState);
				}}
				push={0}
				footer={reviewCartTotal}
				footerStyle={{
					height: "105px",
				}}
				content={
					<div
						className={classnames(
							"bg-white-darker pb-2 overflow-auto",
							style.reviewCartContainer
						)}
					>
						{orderForForm}
						<CartItemList
							cartItems={cart}
							closeCart={() => props.close()}
							fetchTotalPrice={calculateTotalPrice}
							setCart={setCart}
							invalidItems={invalidItems}
							setInvalidItems={setInvalidItems}
							fetchCart={() => fetchList(requestState)}
							showCart={() =>
								props.show({
									location,
									tableName,
									fetchList,
									requestState,
									cart,
									setCart,
								})
							}
						/>
						{reviewCartNote}
					</div>
				}
				{...props}
			/>
			<OrderConfirmation
				loading={placingOrder}
				data={mappedData || {}}
				{...orderConfirmationDrawer}
			/>
		</>
	);
};

export default ReviewCart;
